import request from '@/utils/request';
// 查询关注列表
export function getPhysicianList(data) {
  return request({
    url: `/api/standard/OData/VUserAlignments?$filter=user_id eq '${data.user_id}'&$orderby=attention_score desc`,
    method: 'get'
  });
}
// 查询医生详情
export function getDoctorDetail(data) {
  return request({
    url: `/api/standard/OData/VUserAlignments?$filter=user_id eq '${data.user_id}' and physician_id eq '${data.physician_id}'`,
    method: 'get'
  });
}
// 获取已关注医生列表
export function getFavoriteDoctorList(data) {
  return request({
    url: `/api/standard/OData/VUserAlignments?$filter=user_id eq '${data.user_id}' 
    and is_favorite eq ${data.is_favorite}&$orderby=attention_score desc&$expand=physician`,
    method: 'get'
  });
}
// 添加 or 取消关注
export function addFavorite(data) {
  return request({
    url: `/api/standard/UserFavoritePhysicians`,
    method: data.user_favorite_physician_id ? 'put' : 'post',
    data: data
  });
}
// 置顶
export function updateTopping(data) {
  return request({
    url: `/api/standard/UserFavoritePhysicians`,
    method: 'put',
    data: data
  });
}
// 添加分型
export function addPerceptions(data) {
  return request({
    url: `/api/standard/OData/PhysicianPerceptions`,
    method: 'post',
    data: data
  });
}
// 更新分型
export function updatePerceptions(data) {
  return request({
    url: `/api/standard/PhysicianPerceptions`,
    method: 'put',
    data: data
  });
}
// 获取最新分型
export function getNewPerceptions(data) {
  return request({
    url: `/api/standard/OData/VPhysicianPerceptionNewests?$filter=physician_id eq '${data.physician_id}' and user_id eq '${data.user_id}'`,
    method: 'get'
  });
}

// 获取行为建议列表
export function getNbaSuggestionsList(data) {
  // $expand=barrier
  return request({
    url: `/api/standard/OData/NbaSuggestions?$filter=user_id eq '${data.user_id}' and territory_id eq '${data.territory_id}' and physician_id eq '${data.physician_id}'
    &$expand=nba_rule($expand=barrier)&$orderby=accepted_time desc`,
    method: 'get'
  });
}
// 行为建议接受方式
export function updateSuggestions(data) {
  return request({
    url: `/api/standard/NbaSuggestions`,
    method: 'put',
    data: data
  });
}
// 行为建议 反馈
export function updateSuggestionsFeedback(data) {
  return request({
    url: `/api/standard/NbaSuggestions`,
    method: 'put',
    data: data
  });
}
// 行为建议 新建建议
export function addSuggestion(data) {
  return request({
    url: `/api/standard/NbaSuggestions`,
    method: 'post',
    data: data
  });
}
// 获取最新沟通风格
export function getNewests(data) {
  return request({
    url: `/api/standard/OData/VPhysicianCommunicationStyleNewests?$filter=user_id eq '${data.user_id}' and physician_id eq '${data.physician_id}'`,
    method: 'get'
  });
}
// 添加沟通风格分型
export function addStyles(data) {
  return request({
    url: `/api/standard/OData/PhysicianCommunicationStyles`,
    method: 'post',
    data: data
  });
}
// 更新沟通风格
export function updateStyles(data) {
  return request({
    url: `/api/standard/PhysicianCommunicationStyles`,
    method: 'put',
    data: data
  });
}
// 获取最新核心障碍
export function getBarriers(data) {
  return request({
    url: `/api/standard/OData/VPhysicianBarrierNewests?$filter=perception_type eq '${data.perception_type}' and user_id eq '${data.user_id}' 
    and physician_id eq '${data.physician_id}'
    &$expand=barrier,cnf_perception_barrier&$orderby=cnf_perception_barrier/barrier_priority`,
    method: 'get'
  });
}
// Rule Library列表
export function getNbaLibrary(data) {
  return request({
    url: `/api/standard/OData/NbaRules?$filter=(rule_type eq 'physician' and barrier_id ne null and physician_segment eq '${data.physician_segment}')&$expand=barrier&$orderby=rule_sequence`,
    method: 'get'
  });
}
// 核心障碍 从未填写过
export function getCnfBarriers(data) {
  return request({
    url: `/api/standard/OData/CnfPerceptionBarriers?$filter=perception_type eq '${data.perception_type}'&$expand=barrier&$orderby=barrier_priority`,
    method: 'get'
  });
}
// 添加核心障碍
export function addBarriers(data) {
  return request({
    url: `/api/standard/OData/PhysicianBarriers`,
    method: 'post',
    data: data
  });
}
// 信息检索列表
export function getNbaRulesList() {
  return request({
    url: `/api/standard/OData/NbaRules?$expand=barrier`,
    method: 'get'
  });
}
// 添加  当前行为
export function addPhysicianBehaviors(data) {
  return request({
    url: `/api/standard/PhysicianBehaviors`,
    method: 'post',
    data: data
  });
}
// 修改  当前行为状态
export function updatePhysicianBehaviors(data) {
  return request({
    url: `/api/standard/PhysicianBehaviors`,
    method: 'put',
    data: data
  });
}
// 获取 医生行为
export function getPhysicianBehaviors(data) {
  return request({
    url: `/api/standard/OData/VPhysicianBehaviorNewests?$filter=physician_id eq '${data.physician_id}' and user_id eq '${data.user_id}'`,
    method: 'get'
  });
}
// 添加 医生行为认知细分型
export function addPhysicianBehaviorLadders(data) {
  return request({
    url: `/api/standard/PhysicianBehaviorLadders`,
    method: 'post',
    data: data
  });
}
// 获取 医生行为认知细分型
export function getPhysicianBehaviorLadders(data) {
  return request({
    url: `/api/standard/OData/VPhysicianBehaviorLadderNewests?$filter=physician_id eq '${data.physician_id}' and user_id eq '${data.user_id}'`,
    method: 'get'
  });
}
// 推送文章
export function sendArticle(data) {
  return request({
    url: `/api/gateway/oce/SendArticle?physician_id=${data.physician_id}&user_id=${data.user_id}&user_name=${data.user_name}&article_id=${data.article_id}`,
    method: 'post',
    data: data
  });
}
//
export function getVNbaSuggestions(data) {
  return request({
    url: `/api/standard/OData/VTodoDms?$filter=parent_user_id eq '${data.parent_user_id}' and parent_territory_id eq '${data.parent_territory_id}'
    &$expand=physician,user`,
    method: 'get'
  });
}
