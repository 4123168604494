import * as UA from '@/api/UserAlignment';

export default {
  methods: {
    async addFollowFn(item) {
      let data = {
        'physician_id': item.physician_id,
        'is_favorite': item.is_favorite === 1 ? 0 : 1,
        'user_id': item.user_id,
        'is_top': item.is_favorite === 1 ? 0 : 1
      };
      if (item.user_favorite_physician_id) {
        data['user_favorite_physician_id'] = item.user_favorite_physician_id;
      }
      if (item.is_favorite) { // 取消关注时，置顶也取消
        data['is_top'] = 0;
      }
      return await UA.addFavorite(data);
    },
    addFn(item, $index) {
      const toast = this.$createToast({
        time: 1200,
        mask: true,
        $events: {}
      });
      toast.show();
      this.addFollowFn(item, $index).then(res => {
        if (res.is_success) {
          if (this.isHcpHeader) {
            this.$emit('refreshFn');
          } else {
            this.refreshFn();
          }
        }
      }).catch(e => {
        if (this.isHcpHeader) {
          this.$emit('refreshFn');
        } else {
          this.refreshFn();
        }
      });
    },
    addFavoriteFn(item, status) {
      this.$store.dispatch('setDialogStatus', true);
      let msg = item.is_favorite ? '确定取消关注吗？' : '确定关注吗？';
      this.$createDialog({
        type: 'confirm',
        showClose: true,
        title: this.$t('tips_title'),
        content: msg,
        confirmBtn: {
          text: this.$t('confirm_txt'),
          active: true,
          disabled: false,
          href: 'javascript:;'
        },
        cancelBtn: {
          text: this.$t('cancel_txt'),
          active: false,
          disabled: false,
          href: 'javascript:;'
        },
        onConfirm: () => {
          this.addFn(item);
        },
        onCancel: () => {
          this.$store.dispatch('setDialogStatus', false);
        }
      }).show();
    }
  }
};
